import React from "react"
import Catalog from "../components/Catalog"

const goods = () => {
    return (
        <>
            <Catalog />
        </>
    )
}

export default goods
